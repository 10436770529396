<template>
	<div class="bg-white p-4 rounded border shadow-sm h-100">
		<b-overlay :show="loading" variant="light" spinner-type="grow">

			<div class="jumbotron jumbotron-fluid">
				<div data-v-22ba47ca="" class="container text-white">
					<h1 class="display-4 bg-dark mb-0 p-4">Welcome to Kick 2.0</h1>
					<p class="lead bg-dark p-4">Club student and payment manager for cliveelliott.net</p></div>
			</div>

			<div class="card-deck">
				<div class="card text-white bg-primary">
					<div class="display-3 text-center align-middle pt-4">{{ students.length }}</div>
					<div class="card-body">
						<h5 class="card-title text-center">Total students</h5>
					</div>
				</div>
				<div class="card text-white bg-primary">
					<div class="display-3 text-center align-middle pt-4">£{{ monthlyIncome }}</div>
					<div class="card-body">
						<h5 class="card-title text-center">Monthly income due</h5>
					</div>
				</div>
				<div class="card text-white bg-primary">
					<div class="display-3 text-center align-middle pt-4">£{{ receivedThisMonth }}</div>
					<div class="card-body">
						<h5 class="card-title text-center">Received this month</h5>
					</div>
				</div>
			</div>

			<b-card
					header-bg-variant="info"
					header-text-variant="white"
					:footer="`Last checked: ${noticesFooter} ago`"
					footer-tag="footer"
					class="mt-4"
			>

				<template #header>
					<h2 class="mt-1">Notices</h2>
				</template>

				<b-list-group>
					<b-list-group-item
							v-for="notice in notices"
							:key="notice.id"
							class="d-flex justify-content-between align-items-center"
							:variant="notice.variant"
					>
						<b-icon v-if="!notice.disableDelete" icon="exclamation-triangle" variant="success" font-scale="2"></b-icon>
						<span style="font-size: 1.2rem;">{{ notice.message }}</span>
						<b-link v-if="!notice.disableDelete" @click="dismissNotice(notice)" v-b-tooltip.hover title="Dismiss this notice">
							<b-icon icon="trash" variant="danger" font-scale="2"></b-icon>
						</b-link>
					</b-list-group-item>
				</b-list-group>
			</b-card>

		</b-overlay>
	</div>
</template>

<script>

import { DateTime }     from 'luxon';
import { v4 as UUID }   from 'uuid';
import humanizeDuration from 'humanize-duration' ;

export default {

    name      : 'Dashboard',
    mixins    : [],
    components: {},

    props: {},

    data () {
        return {
            loading           : true,
            students          : [],
            notices           : [],
            timerNow          : null,
            timerFetchNotices : null,
            noticesLastFetched: null,
            now               : null
        };
    },

    computed: {

        monthlyIncome () {
            return parseInt (
                this.students
                    .reduce ( ( n, { totalFeesDue } ) => n + totalFeesDue, 0 )
                    .toFixed ( 0 ),
                10
            )
                .toLocaleString ();
        },

        receivedThisMonth () {
            return parseInt (
                this.students
                    .reduce ( ( n, { totalFeesPaidThisMonth } ) => n + totalFeesPaidThisMonth, 0 )
                    .toFixed ( 0 ),
                10
            )
                .toLocaleString ();
        },

        noticesFooter () {
            return this.noticesLastFetched ? humanizeDuration (
                this.now - ( new Date ( this.noticesLastFetched ) ),
                { units: [ 'm', 's' ], round: true }
            ) : '-';
        }

    },

    methods: {

        async fetchNotices () {
            try {

                let response = await this.$http.get (
                    `${ this.config.apiRoot }/notices?dismissed=false`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                if ( response.data.records.length === 0 ) {
                    this.notices = [
                        {
                            id           : UUID (),
                            disableDelete: true,
                            message      : 'You\'re all caught up, you have no unread notices!'
                        }
                    ];
                } else {
                    this.notices = response.data.records;
                }

                this.noticesLastFetched = Date.now ();

            } catch ( e ) {
                this.notices.push ( {
                    id     : UUID (),
                    variant: 'error',
                    message: `There was an error fetching notices: ${ e.message }`
                } );
            }
        },

        async dismissNotice ( notice ) {
            try {

                // Update the notice
                await this.$http.put (
                    `${ this.config.apiRoot }/notice/${ notice.id }`,
                    {
                        dismissed: true
                    },
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                // Refresh notices
                await this.fetchNotices ();

            } catch ( e ) {
                this.$utils.logger ( 'error', 'Error dismissing notice', e );
            }
        }

    },

    async mounted () {

        // Get total student count
        try {

            // Get students
            let response = await this.$http.get (
                `${ this.config.apiRoot }/students`,
                {
                    responseType   : 'json',
                    withCredentials: false // CORS dev server
                }
            );
            const dt = DateTime.local ();
            this.students = response.data.records.map ( x => {

                x.totalFeesDue = x.classes.reduce ( ( n, { monthlyFee } ) => n + monthlyFee, 0 );
                x.totalFeesPaidThisMonth = x.payments
                    .filter ( x => {
                        const d = DateTime.fromISO ( x.paymentDate );
                        return d.month === dt.month;
                    } )
                    .reduce ( ( n, { amount } ) => n + amount, 0 )
                ;
                return x;

            } );

            // Fetch notices
            await this.fetchNotices ();

            // Setup last updated timer to update computed property
            this.timerNow = setInterval ( () => this.now = new Date (), 500 );

            // Setup timer to refresh balances
            this.timerFetchNotices = setInterval ( async () => await this.fetchNotices (), 5000 );

            this.loading = false;

        } catch ( e ) {

            // Bubble the exception
            this.error = this.$utils.normaliseError ( e );
            this.$utils.logger ( 'error', 'Error fetching student count:', e );
            return [];

        }

    },

    beforeDestroy () {
        if ( this.timerNow ) {
            clearInterval ( this.timerNow );
            this.timerNow = null;
        }
        if ( this.timerFetchNotices ) {
            clearInterval ( this.timerFetchNotices );
            this.timerFetchNotices = null;
        }
    }

};

</script>

<style scoped lang="scss">
.jumbotron {
	background-image: url("../assets/banner.jpg");
	background-size: cover;
}
</style>
